import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    headerLogo: null,
    newDeviceLogin: false,
  },
  mutations: {
    changeMutation(state, logo) {
      state.headerLogo = logo
    },
    notiNewDeviceLogin(state, status) {
      state.newDeviceLogin = status
    },
  },
  actions: {},
  modules: {},
})
