<template>
  <div id="app">
    <a-page-header
      v-if="
        $route.name !== 'Login' &&
        $route.name !== 'Register' &&
        $route.name !== 'Home' &&
        $route.name !== 'AdminLogin' &&
        $route.name !== 'AdminMessageScreen'
      "
      style="border: 1px solid rgb(235, 237, 240)"
      @back="goBack"
    >
      <span slot="backIcon">
        <a-icon v-if="$route.name !== 'Sessions'" slot="backIcon" type="left" />
      </span>
      <div
        v-if="
          $route.name !== 'AdminDashboard' &&
          $route.name !== 'AdminSession' &&
          $route.name !== 'AdminUsersLog' &&
          $route.name !== 'AdminUser'
        "
        id="logo-wrapper"
        @click.prevent="goToSessions()"
        slot="subTitle"
      >
        <img
          id="logo"
          :src="require('@/assets/img/icreator-conference-2022-logo.png')"
        />
      </div>
      <div v-else id="logo-wrapper" slot="subTitle">
        <img id="logo" :src="livetubeLogo" />
      </div>
      <div class="extra">
        <div
          v-if="this.$route.params.projectSlug == 'live'"
          class="desktop-menu"
        >
          <a-menu v-model="current" mode="horizontal">
            <a-menu-item key="sessions" class="stages">
              <a
                href="#"
                rel="noopener noreferrer"
                class="block"
                @click.prevent="goToSessions()"
                ><a-icon class="hidden sm:inline-block" type="laptop" />
                SESSIONS</a
              >
            </a-menu-item>
            <a-menu-item key="schedules">
              <a
                href="#"
                @click.prevent="goToSchedules()"
                rel="noopener noreferrer"
                class="block"
                ><a-icon class="hidden sm:inline-block" type="calendar" />
                SCHEDULES</a
              >
            </a-menu-item>
            <a-menu-item key="support">
              <a
                href="https://www.m.me/rainmakerth"
                rel="noopener noreferrer"
                target="_blank"
                class="block"
                ><a-icon
                  class="hidden sm:inline-block"
                  type="customer-service"
                />
                SUPPORT</a
              >
            </a-menu-item>
            <a-menu-item key="faq">
              <a
                href="#"
                @click.prevent="goToFaq()"
                rel="noopener noreferrer"
                class="block"
                ><a-icon
                  class="hidden sm:inline-block"
                  type="question-circle"
                />
                FAQ</a
              >
            </a-menu-item>
          </a-menu>
        </div>
        <a-dropdown placement="bottomCenter">
          <a-avatar shape="circle" icon="user" />
          <a-menu slot="overlay">
            <a-menu-item v-if="user">
              <span>{{ user.name }}</span>
            </a-menu-item>
            <a-menu-item>
              <span @click="logout()">Logout</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </a-page-header>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      livetubeLogo: require('@/assets/logo.svg'),
      setLoginInterval: null,
      duplicateLoginCount: 0,
      user: null,
      loginStatus: {},
      current: [],
      project: [],
    }
  },
  async mounted() {
    if (!localStorage.uuid) {
      localStorage.uuid = this.uuidv4()
    }
    this.setLoginInterval = setInterval(async () => {
      if (this.$route.params.projectSlug) {
        if (
          localStorage[
            `auth:live-tube-portal:${this.$route.params.projectSlug}`
          ]
        ) {
          const userData = JSON.parse(
            localStorage[
              `auth:live-tube-portal:${this.$route.params.projectSlug}`
            ]
          )
          this.user = userData
          const messageRef = this.$rdb.ref(
            `user-login-status/${this.$route.params.projectSlug}/${userData.username}`
          )
          messageRef.once('value', (snapshot) => {
            if (snapshot.val()) {
              const loginStatus = snapshot.val()
              if (loginStatus.uuid !== localStorage.uuid) {
                // this.$store.commit('notiNewDeviceLogin', true)
                localStorage.removeItem(
                  `auth:live-tube-portal:${this.$route.params.projectSlug}`
                )
                this.$router.push({
                  name: 'Login',
                  params: {
                    projectSlug: this.$route.params.projectSlug,
                  },
                })
              }
            }
          })
        }
      }
    }, 5000)
  },
  async created() {
    this.current = (await this.$route.name)
      ? [this.$route.name.toLowerCase()]
      : []
  },
  methods: {
    logout() {
      const userData = JSON.parse(
        localStorage.getItem(
          `auth:live-tube-portal:${this.$route.params.projectSlug}`
        )
      )
      if (userData) {
        localStorage.removeItem(
          `auth:live-tube-portal:${this.$route.params.projectSlug}`
        )
        this.$router.push({
          name: 'Login',
          params: {
            projectSlug: this.$route.params.projectSlug,
          },
        })
      } else {
        const currentUser = this.$auth.currentUser
        this.$auth.signOut()
        if (currentUser) {
          this.$router.push({ name: 'AdminLogin' })
        }
      }
      // const userData = JSON.parse(
      //   localStorage.getItem(
      //     `auth:live-tube-portal:${this.$route.params.projectSlug}`
      //   )
      // )
      // if (userData) {
      //   this.updateLoginStatus(userData)
      // }
      // this.$auth.signOut()
      // localStorage.removeItem(
      //   `auth:live-tube-portal:${this.$route.params.projectSlug}`
      // )
      // localStorage.removeItem('last_session')
      // setTimeout(() => {
      //   this.$router.go()
      // }, 1000)
    },
    updateLoginStatus(userData) {
      this.$db.collection('login-status').doc(userData.uid).delete()
    },
    goBack() {
      this.$router.go(-1)
    },
    goToSessions() {
      this.$router.push({
        name: 'Sessions',
        params: {
          projectSlug: this.$route.params.projectSlug,
        },
      })
    },
    goToSchedules() {
      this.$router.push({
        name: 'SchedulesPage',
        params: {
          projectSlug: this.$route.params.projectSlug,
        },
      })
    },
    goToFaq() {
      this.$router.push({
        name: 'FaqPage',
        params: {
          projectSlug: this.$route.params.projectSlug,
        },
      })
    },
    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0
          const v = c === 'x' ? r : (r & 0x3) | 0x8
          return v.toString(16)
        }
      )
    },
  },
}
</script>
<style lang="scss">
@font-face {
  font-family: 'anuphan';
  src: url('assets/fonts/anuphan-light.woff2') format('woff2'),
    url('assets/fonts/anuphan-light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'anuphan';
  src: url('assets/fonts/anuphan-medium.woff2') format('woff2'),
    url('assets/fonts/anuphan-medium.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'anuphan';
  src: url('assets/fonts/anuphan-regular.woff2') format('woff2'),
    url('assets/fonts/anuphan-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'anuphan';
  src: url('assets/fonts/anuphan-semibold.woff2') format('woff2'),
    url('assets/fonts/anuphan-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

// audio,
// canvas,
// embed,
// iframe,
// object,
// svg,
// video {
img {
  margin: 0 auto;
}

.logo-loading {
  font-size: 1.3em;
  padding-left: 15px;
}

#logo-wrapper {
  text-align: center;
}
#logo {
  height: 20px;
}

.ant-page-header.ant-page-header-ghost {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding-top: 18px;
  padding-bottom: 18px;
  max-height: 70px;
  background-color: #fff;
  z-index: 100000;
}

@media (max-width: 768px) {
  .ant-page-header.ant-page-header-ghost {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100000;
  }
}

@media (max-width: 576px) {
  .ant-page-header.ant-page-header-ghost {
    grid-template-columns: 1fr 2fr;
    padding: 8px;
    max-height: 50px;
  }
}

.ant-page-header.ant-page-header-ghost > div {
  padding-top: 0 !important;
}

.extra {
  float: right;
}
.desktop-menu {
  display: inline-block;
  margin-right: 30px;
  vertical-align: top;
  ul {
    vertical-align: top;
    margin: 0;
    list-style: none;
    background: transparent;
    border: 0;
    li {
      display: inline-block;
      padding-left: 15px;
      padding-right: 15px;
      color: #a29cae;
      font-weight: 700;
      a {
        display: block;
        font-weight: 700;
        line-height: 50px;
        color: #a29cae;
      }
    }
  }
}
@media (max-width: 576px) {
  .desktop-menu {
    margin-right: 10px;
    ul {
      li {
        padding-left: 7px;
        padding-right: 7px;
        &.stages {
          display: none;
        }
      }
    }
  }
}
</style>
